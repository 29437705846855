import { takeEvery, put, call } from "redux-saga/effects"
import { $host, $authHost } from "store/axios"
import {
  actionTypes,
  getRadiatorsSettingsSuccess,
  updateRadiatorsSettingsSuccess,
  resetRadiatorsSettingsStates,
} from "./action"
import { notification } from "antd"

// get radiators
function* radiatorsSettings(action) {
  const { payload } = action
  try {
    const response = yield $authHost.get(
      `/org/radiators/settings/${payload.org_id}`
    )
    yield put(getRadiatorsSettingsSuccess(response.data.data.temperatures))
  } catch (error) {
    yield console.error(error)
  }
}

// update radiators
function* updateRadiator(action) {
  const { payload } = action
  const updateRadiator = {
    org_id: payload.org_id,
    min_temp: payload.minimumtemperature,
    max_temp: payload.maximumtemperature,
  }
  try {
    const response = yield $authHost.post(
      `/org/radiators/settings`,
      updateRadiator
    )
    notification.success({
      message: response.data.message,
      description: response.data.description,
    })
    yield put(updateRadiatorsSettingsSuccess(true))
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetRadiatorsSettingsStates())
    }
  }
}

function* radiatorsSaga() {
  yield takeEvery(actionTypes.GET_RADIATORS_SETTINGS, radiatorsSettings)
  yield takeEvery(actionTypes.UPDATE_RADIATORS_SETTINGS, updateRadiator)
}

export default radiatorsSaga
