import { all, fork } from "redux-saga/effects"

//public
import dashboardSaga from "./dashboard/saga"
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import resourcesRootSaga from "./resources/saga"
import radiatorsSaga from "./radiators/saga"
import brandingRootSaga from "./branding/saga"
import notificationRootSaga from "./notification/saga"
export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    dashboardSaga(),
    resourcesRootSaga(),
    radiatorsSaga(),
    brandingRootSaga(),
    LayoutSaga(),
    notificationRootSaga(),
    fork(calendarSaga),
  ])
}
