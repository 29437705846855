import PropTypes from "prop-types"
import React, { useState } from "react"
import { Form, Input, Upload, Space } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
// Custom Scrollbar
import SimpleBar from "simplebar-react"
import { useSelector } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

const Settings = props => {
  const [form] = Form.useForm()
  document.title = "Settings | TRUST"
  const { currentUser } = useSelector(state => state.Login)
  // console.log("currentUsercurrentUser", currentUser)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb breadcrumbItem="Settings" />
          <Row>
            <Col>
              <Form
                form={form}
                layout="vertical"
                size="large"
                initialValues={{
                  companyName:
                    currentUser.first_name + " " + currentUser.last_name,
                  addressLine1: currentUser.address_line1,
                  addressLine2: currentUser.address_line2,
                  contactnumber: currentUser.mobile,
                  email: currentUser.email,
                  postcode: currentUser.post_code,
                }}
                // onValuesChange={onRequiredTypeChange}
                // requiredMark={
                //   requiredMark === "customize"
                //     ? customizeRequiredMark
                //     : requiredMark
                // }
              >
                <Form.Item
                  name="clientName"
                  label="Client Name"
                  rules={[
                    {
                      // required: true,
                      message: "Enter Client Name",
                    },
                  ]}
                >
                  <Input placeholder="Client Name" />
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name="addressLine1"
                    label="Address line 1"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your Address line 1!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Input placeholder="Enter Address Line one" />
                  </Form.Item>
                  <Form.Item
                    name="addressLine2"
                    label="Address line 2"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your Address line 2!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Input placeholder="Enter Address line 2" />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name="contactnumber"
                    label="Contact Number"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your Contact Number!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Input placeholder="Enter Contact Number" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        // required: true,
                        message: "Please Enter Email",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Input placeholder="Enter Your Email" />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name="postcode"
                    label="Post Code"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your Post Code!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Input placeholder="Enter Post Code" />
                  </Form.Item>
                  <Form.Item
                    name="geofencingRadius"
                    label="Geofencing Radius"
                    rules={[
                      {
                        // required: true,
                        message: "Please input Geofencing Radius",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Input placeholder="Enter Geofencing Radius" />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Space style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      className="bg-primary w-xl text-white px-4"
                      type="primary"
                      htmltype="submit"
                    >
                      Save Changes
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Settings.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Settings)
