import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Label,
  Form,
  FormFeedback,
  Input,
} from "reactstrap"

import { Spin } from "antd"

// Redux
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, apiError, resetLoginStates } from "../../store/actions"

// import images
import logoSm from "../../assets/images/trust-logo.png"

const Login = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [userLogin, setUserLogin] = useState([])

  const { user } = useSelector(state => ({
    user: state.Account.user,
    shallowEqual,
  }))

  const { isLoading, success } = useSelector(state => ({
    success: state.Login.success,
    isLoading: state.Login.loading,
  }))

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        email: user.email,
        password: user.password,
      })
    }
  }, [user])

  useEffect(() => {
    if (success) {
      navigate("/dashboard")
      dispatch(resetLoginStates())
    }
  }, [success])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || "",
      password: userLogin.password || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your User Name"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values))
    },
  })

  document.title = "Login | TRUST - "
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        {/* <Container> */}
        <Row className="justify-content-center">
          <Col
            md={12}
            lg={6}
            xl={7}
            className="d-flex justify-content-center align-items-center flex-column"
          >
            <img src={logoSm} width={"50%"} alt="logo" />
            <br />
            <h1 className="font-size-10">heating for humans</h1>
          </Col>
          <Col md={12} lg={6} xl={4}>
            <Card className="overflow-hidden">
              <div className="">
                <div className="text-primary p-4">
                  <h5 className="font-size-20">Welcome To Trust !</h5>
                </div>
              </div>

              <CardBody className="p-4">
                <div className="p-3">
                  <Form
                    className="mt-4"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    action="#"
                  >
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="username">
                      Email Address
                      </Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Email address"
                        type="email"
                        id="username"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label" htmlFor="userpassword">
                      Password
                      </Label>
                      <Input
                        name="password"
                        value={validation.values.password || ""}
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 row ">
                      <div className="col-sm-8 text-end">
                        <button
                          className="btn btn-primary w-xl waves-effect waves-light"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? <Spin /> : "Sign In"}
                        </button>
                      </div>
                    </div>

                    {/* <div className="mt-2 mb-0 row">
                      <div className="col-12 mt-4">
                        <Link to="/forgot-password">
                          <i className="mdi mdi-lock"></i> Forgot your password?
                        </Link>
                      </div>
                    </div> */}
                  </Form>
                </div>
              </CardBody>
            </Card>
            {/* <div className="mt-5 text-center">
              <p>
                Don&#39;t have an account ?{" "}
                <Link to="/register" className="fw-medium text-primary">
                  {" "}
                  Signup now{" "}
                </Link>{" "}
              </p>
            </div> */}
          </Col>
        </Row>
        {/* </Container> */}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
