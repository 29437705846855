import React from "react"

// Profile
import UserProfile from "pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
import Register from "pages/Authentication/Register"
import ForgetPwd from "pages/Authentication/ForgetPassword"

// Super Admin
import Dashboard from "pages/Dashboard"
import BrandingList from "pages/branding/brandinglist"
import EditBranding from "pages/branding/editbranding"
import RadiatorSettings from "pages/radiator-settings/radiator"
import EditRadiator from "pages/radiator-settings/editRadiator"
import PrivacyPolicy from "pages/privacy-policy/privacy-policy"
import TermsConditions from "pages/terms-conditions/terms-conditions"
import AboutUs from "pages/about-us/about-us"
import Settings from "pages/settings"
// gen

import ContactUs from "pages/contactUs/contactUs"

//Pages
import PagesMaintenance from "pages/Utility/pages-maintenance"
import PagesComingsoon from "pages/Utility/pages-comingsoon"
import Pages404 from "pages/Utility/pages-404"
import Pages500 from "pages/Utility/pages-500"

const userRoutes = [
  // this route should be at the end of all other routes
  // Radiator
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/branding", component: <EditBranding /> },
  // { path: "/edit-branding", component: <EditBranding /> },
  { path: "/radiator-settings", component: <RadiatorSettings /> },
  { path: "/edit-radiator-settings", component: <EditRadiator /> },
  { path: "/settings", component: <Settings /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/terms-conditions", component: <TermsConditions /> },
  { path: "/about-us", component: <AboutUs /> },

  // general
  { path: "/contact-us", component: <ContactUs /> },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
  // { path: "/pages-maintenance", component: <PagesMaintenance /> },
  // { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  // { path: "/pages-404", component: <Pages404 /> },
  // { path: "/pages-500", component: <Pages500 /> },
]

export { userRoutes, authRoutes }
