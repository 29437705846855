export const actionTypes = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  // read/unread notifications
  READ_UNREAD_NOTIFICATIONS: "READ_UNREAD_NOTIFICATIONS",
  // Mark All As read
  MARK_ALL_READ_NOTIFICATIONS: "MARK_ALL_READ_NOTIFICATIONS",
  // Delete Notifications
  DELETE_NOTIFICATIONS: "DELETE_NOTIFICATIONS",
  DELETE_ALL_NOTIFICATIONS: "DELETE_ALL_NOTIFICATIONS",
}

export const getNotifications = () => ({
  type: actionTypes.GET_NOTIFICATIONS,
})

export const getNotificationsSuccess = payload => ({
  type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
  payload,
})

// read/unread notifications

// Mark All As read

// Delete Notifications
