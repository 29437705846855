import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Form, Input, Upload, Space, ColorPicker } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"
import { Link, useNavigate, useLocation } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
//i18n
import { withTranslation } from "react-i18next"
import {
  updateRadiatorsSettings,
  resetRadiatorsSettingsStates,
} from "store/radiators/action"

const EditRadiator = props => {
  document.title = "Radiators Settings | TRUST"
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location
  const { currentUser } = useSelector(state => state.Login)
  const { loading, success } = useSelector(state => state.RadiatorReducer)
  const isUpdateForm = state?.record && Object.keys(state?.record).length > 0

  useEffect(() => {
    if (success) {
      form.resetFields()
      dispatch(resetRadiatorsSettingsStates())
      navigate("/radiator-settings")
    }
  }, [success])

  useEffect(() => {
    if (isUpdateForm) {
      form.setFieldValue("minimumtemperature", state.record.min_temp)
      form.setFieldValue("maximumtemperature", state.record.max_temp)
    }
  }, [state?.record])

  const validateTemperatureRange = (_, value) => {
    const minTemp = form.getFieldValue("minimumtemperature")
    const maxTemp = form.getFieldValue("maximumtemperature")
    if (minTemp > maxTemp) {
      return Promise.reject(
        "Minimum temperature cannot be greater than maximum temperature"
      )
    }
    return Promise.resolve()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb breadcrumbItem="Edit Branding" />
          <Row>
            <Col>
              <Form
                form={form}
                layout="vertical"
                size="large"
                initialValues={{}}
                onFinish={values => {
                  values.org_id = currentUser.organization_id
                  dispatch(updateRadiatorsSettings(values))
                }}
              >
                <Form.Item>
                  <Form.Item
                    name="minimumtemperature"
                    label="Minimum Temperature"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Minimum Temperature!",
                      },
                      {
                        validator: validateTemperatureRange,
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Input placeholder="20 C" />
                  </Form.Item>
                  <Form.Item
                    name="maximumtemperature"
                    label="Maximum Temperature"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Maximum Temperature!",
                      },
                      {
                        validator: validateTemperatureRange,
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Input placeholder="10 C" />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Space style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      className="bg-primary w-xl text-white px-4"
                      type="primary"
                      htmltype="submit"
                      disabled={loading}
                    >
                      Save Settings
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EditRadiator.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(EditRadiator)
