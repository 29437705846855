import { takeEvery, put, call } from "redux-saga/effects"
import { $host, $authHost } from "store/axios"
import { actionTypes, getNotificationsSuccess } from "./actions"
import { notification } from "antd"

function* getNotificationsSaga() {
  try {
    const response = yield $authHost.get("notifications")
    yield put(getNotificationsSuccess(response.data.data.notifications))
  } catch (error) {
    yield console.error(error)
  }
}

function* notificationRootSaga() {
  yield takeEvery(actionTypes.GET_NOTIFICATIONS, getNotificationsSaga)
}

export default notificationRootSaga
