import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { Link } from "react-router-dom"
import { Typography, Statistic } from "antd"
import Breadcrumb from "../../components/Common/Breadcrumb"
// Custom Scrollbar
import SimpleBar from "simplebar-react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { getDashboardData } from "store/dashboard/actions"
import { ReloadOutlined } from "@ant-design/icons"
//i18n
import { withTranslation } from "react-i18next"
const { Title, Text } = Typography
const Dashboard = props => {
  document.title = "Dashboard | TRUST - "
  const dispatch = useDispatch()
  const [menu, setMenu] = useState(false)
  const { currentMenu } = useSelector(state => state.Layout)
  const { currentUser } = useSelector(state => state.Login)
  const { dashboardData } = useSelector(state => state.Dashboard, shallowEqual)
  const toggle = () => {
    setMenu(!menu)
  }

  useEffect(() => {
    dispatch(getDashboardData())
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" />
          <Row>
            <Col md={12}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  float: "right",
                  gap: 10,
                }}
              >
                <Title level={4} style={{ margin: 0 }}>
                  Access Code:{" "}
                </Title>
                <Title level={5} className="text-primary" style={{ margin: 0 }}>
                  {currentUser?.organization?.access_code}
                </Title>
                {/* <Button className="bg-primary text-white">
                  <ReloadOutlined />
                </Button> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={4} md={6} className="my-3">
              <Card className="h-100">
                <CardBody className="text-center h-100 d-flex flex-column justify-content-between">
                  <h5 className="fw-medium font-size-24">
                    Number of Radiators
                  </h5>
                  <h4 className="fw-medium font-size-24 text-primary">
                    <Statistic value={dashboardData[0]?.radiators} />
                  </h4>
                  {/* <Button className="bg-primary text-white">
                    View Details
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6} className="my-3">
              <Card className="h-100">
                <CardBody className="text-center h-100 d-flex flex-column justify-content-between">
                  <h5 className="fw-medium font-size-24">
                    Number of Radiators in use{" "}
                  </h5>
                  <h4 className="fw-medium font-size-24 text-primary">
                    <Statistic value={dashboardData[0]?.active_radiators} />
                  </h4>
                  {/* <Button className="bg-primary text-white">
                    View Details
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
            {/* <Col xl={4} md={6} className="my-3">
              <Card className="h-100">
                <CardBody className="text-center h-100 d-flex flex-column justify-content-between">
                  <h5 className="fw-medium font-size-24">Kins </h5>
                  <h4 className="fw-medium font-size-24 text-primary">
                    <Statistic value={dashboardData[0]?.kins} />
                  </h4>
                  <Button className="bg-primary text-white">
                    View Details
                  </Button>
                </CardBody>
              </Card>
            </Col> */}
            <Col xl={4} md={6} className="my-3">
              <Card className="h-100">
                <CardBody className="text-center h-100 d-flex flex-column justify-content-between">
                  <h5 className="fw-medium font-size-24">Users </h5>
                  <h4 className="fw-medium font-size-24 text-primary">
                    <Statistic value={dashboardData[0]?.users} />
                  </h4>
                  {/* <Button className="bg-primary text-white">
                    View Details
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
