import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import "react-quill/dist/quill.snow.css"
import { Link, useNavigate } from "react-router-dom"
import { Form, Table, Select, DatePicker, TimePicker, Typography } from "antd"
import { Container, Row, Button, Col } from "reactstrap"
import ReactQuill from "react-quill"
import Breadcrumb from "../../components/Common/Breadcrumb"
import dayjs from "dayjs"
import { getAboutUs, updateAbout } from "store/resources/action"
import { useDispatch, useSelector } from "react-redux"
const { Title, Text } = Typography
//i18n
import { withTranslation } from "react-i18next"
const { Option } = Select
const AboutUs = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, aboutUsData } = useSelector(state => state.ResourcesReducer)
  const { currentUser } = useSelector(state => state.Login)
  const [value, setValue] = useState("")

  const handleChange = content => {
    setValue(content)
  }

  useEffect(() => {
    dispatch(getAboutUs({ id: currentUser.organization_id }))
  }, [])

  useEffect(() => {
    if (aboutUsData?.details) {
      setValue(aboutUsData?.details)
    }
  }, [aboutUsData?.details])

  const handleSaveChanges = () => {
    const payload = {
      id: currentUser.id,
      data: { details: value },
    }
    dispatch(updateAbout(payload))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="About Us" />
          <Row>
            <Col>
              <Title level={3}>About Us</Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <ReactQuill
                value={value}
                onChange={handleChange}
                // theme="snow"
                modules={{ toolbar: true }}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <Button
                className="bg-primary w-xl text-white"
                style={{
                  float: "right",
                }}
                onClick={handleSaveChanges}
                disabled={loading}
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AboutUs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AboutUs)
