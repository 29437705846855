import { actionTypes } from "./actions"

const INIT_STATE = {
  clientBranding: {},
  loading: false,
}

const Branding = (state = INIT_STATE, action) => {
  switch (action.type) {
    //get client Branding
    case actionTypes.GET_CLIENT_BRANDING:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_CLIENT_BRANDING_SUCCESS:
      return {
        ...state,
        loading: false,
        clientBranding: action.payload.data,
      }
    // update branding
    case actionTypes.UPDATE_BRANDING:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.RESET_BRANDING_STATE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default Branding
