import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { sidebarData } from "common/sidebarData"
import radiatorIcon from "assets/images/radiatorIcon.png"
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from "@ant-design/icons"
import { Menu } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { toggleSidebar } from "store/dashboard/actions"

import { useLocation, Link, useNavigate } from "react-router-dom"

const SidebarContent = () => {
  const dispatch = useDispatch()
  const { location } = useLocation()
  let navigate = useNavigate()
  const { sideBarCollapse } = useSelector(state => state.Dashboard)
  const [selectedKey, setSelectedKey] = useState("")
  const [openKeys, setOpenKeys] = useState([])

  // Map sidebarData to generate menu items
  const items = sidebarData.map(item => {
    if (item.childerns && item.childerns.length > 0) {
      const childrenItems = item.childerns.map(child => ({
        key: child.id.toString(),
        label: child.title,
        href: child.href,
        // icon: <img src={child.icon} height={18} /> ,
      }))
      return {
        key: item.id.toString(),
        label: item.title,
        icon: <img src={item.image} height={18} />,
        children: childrenItems,
        type: "subMenu",
      }
    } else {
      return {
        key: item.id.toString(),
        label: item.title,
        href: item.href,
        icon: <img src={item.image} height={18} />,
        type: "menuItem",
      }
    }
  })

  useEffect(() => {
    let currentSelectedItem = null

    items.forEach(item => {
      if (!item.children && item.key === selectedKey) {
        currentSelectedItem = item
      } else if (item.children) {
        const selectedChild = item.children.find(
          child => child.key === selectedKey
        )
        if (selectedChild) {
          currentSelectedItem = selectedChild
        }
      }
    })
    navigate(currentSelectedItem?.href)
  }, [selectedKey])

  const handleMenuSelect = ({ key }) => {
    setSelectedKey(key)
  }

  const handleSubMenuOpenChange = keys => {
    setOpenKeys(keys)
  }

  return (
    <div
      style={{
        maxHeight: "calc(100vh - 64px)",
        overflowY: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: "lightgray",
      }}
    >
      <Menu
        mode="inline"
        // theme="dark"
        inlineCollapsed={sideBarCollapse}
        items={items}
        //   selectedKeys={selectedKeys}
        //   openKeys={openKeys}
        onSelect={handleMenuSelect}
        onOpenChange={handleSubMenuOpenChange}
      />
    </div>
  )
}

SidebarContent.propTypes = {
  //   location: PropTypes.object,
}

export default withRouter(withTranslation()(SidebarContent))
