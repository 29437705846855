import aboutIcon from "assets/images/about.png"
import contactusIcon from "assets/images/contactus.png"
import dashboardIcon from "assets/images/sideBar/dashboardIcon.png"
import brandingIcon from "assets/images/sideBar/brandingBadge.png"
import settingsIcon from "assets/images/sideBar/settings.png"
import radiatorIcon from "assets/images/sideBar/radiator.png"
import termsIcon from "assets/images/sideBar/terms.png"
import privacyIcon from "assets/images/sideBar/privacy-policy.png"
export const sidebarData = [
  {
    id: 1,
    title: "Dashboard",
    image: dashboardIcon,
    href: "/dashboard",
    icon: false,
  },
  {
    id: 2,
    title: "Branding",
    image: brandingIcon,
    href: "/branding",
    icon: false,
  },
  {
    id: 3,
    title: "Radiator Settings",
    image: radiatorIcon,
    href: "/radiator-settings",
    icon: false,
  },
  // {
  //   id: 4,
  //   title: "Settings",
  //   image: settingsIcon,
  //   href: "/settings",
  //   icon: false,
  // },

  {
    id: 5,
    title: "Terms & Conditions",
    image: termsIcon,
    href: "/terms-conditions",
    icon: false,
  },
  {
    id: 6,
    title: "Privacy Policy",
    image: privacyIcon,
    href: "/privacy-policy",
    icon: false,
  },
  {
    id: 7,
    title: "About Us",
    image: aboutIcon,
    href: "/about-us",
    icon: false,
  },
  // {
  //   id: 8,
  //   title: "Contact Us",
  //   image: contactusIcon,
  //   href: "/contact-us",
  //   icon: false,
  // },
]
