import { takeEvery, put, call } from "redux-saga/effects"
import { $host, $authHost } from "store/axios"
import {
  actionTypes,
  getClientBrandingSuccess,
  resetBrandingStates,
} from "./actions"
import { notification } from "antd"

function* getClientBranding(action) {
  const { client_id, application_type } = action.payload
  try {
    const response = yield $authHost.get(
      `/clients/${client_id}?application_type=${application_type}`
    )
    yield put(
      getClientBrandingSuccess({
        applicationType: application_type,
        data: response.data.data.client,
      })
    )
  } catch (error) {
    yield console.error(error)
    yield put(resetBrandingStates())
  }
}

function* updateBranding(action) {
  const { payload } = action
  try {
    const clientPayload = {
      type: payload.typeId,
      client_name: payload.client_name,
      address_line1: payload.AddressLine1,
      address_line2: payload.AddressLine2,
      city: payload.city,
      state: payload.state,
      mobile: payload.contactnumber,
      email: payload.email,
      post_code: payload.postcode,
      country: payload.country,
      geofencing_radius: payload.geofencing_radius,
      primary_color: payload.primarycolor,
      primary_dark_color: payload.primarydark,
      secondary_color: payload.secondarycolor,
      accent_color: payload.accentcolor,
      background_color: payload.backgroundcolor,
      title_text_color: payload.titleTextColor,
      header_text_color: payload.headerTextColor,
      button_text_color: payload.buttonTextColor,
      hub_ip: payload.hupIpAddress ?? null,
      hub_port: payload.hubport ?? null,
      hub_username: payload.hubusername ?? null,
      hub_password: payload.hubconfirmPassword ?? null,
    }
    const response = yield $authHost.post(
      `clients/${payload.client_id}`,
      clientPayload
    )
    if (response.data.code === 200) {
      notification.success({
        message: response.data.message,
        description: response.data.description,
      })
      yield put(resetBrandingStates())
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetBrandingStates())
    }
  }
}

function* brandingRootSaga() {
  yield takeEvery(actionTypes.GET_CLIENT_BRANDING, getClientBranding)
  yield takeEvery(actionTypes.UPDATE_BRANDING, updateBranding)
}

export default brandingRootSaga
