export const actionTypes = {
  GET_ABOUT: "GET_ABOUT",
  GET_ABOUT_SUCCESS: "GET_ABOUT_SUCCESS",
  GET_PRIVACY: "GET_PRIVACY",
  GET_PRIVACY_SUCCESS: "GET_PRIVACY_SUCCESS",
  GET_TERMS: "GET_TERMS",
  GET_TERMS_SUCCESS: "GET_TERMS_SUCCESS",
  //   update about
  UPDATE_ABOUT: "UPDATE_ABOUT",
  UPDATE_PRIVACY: "UPDATE_PRIVACY",
  UPDATE_TERM: "UPDATE_TERM",
  // reset states
  RESET_RESOURCE_STATE: "RESET_RESOURCE_STATE",
}

export const getAboutUs = payload => ({
  type: actionTypes.GET_ABOUT,
  payload,
})

export const getAboutUsSuccess = payload => ({
  type: actionTypes.GET_ABOUT_SUCCESS,
  payload,
})

export const getPrivacy = payload => ({
  type: actionTypes.GET_PRIVACY,
  payload,
})

export const getPrivacySuccess = payload => ({
  type: actionTypes.GET_PRIVACY_SUCCESS,
  payload,
})

export const getTerms = payload => ({
  type: actionTypes.GET_TERMS,
  payload,
})

export const getTermsSuccess = payload => ({
  type: actionTypes.GET_TERMS_SUCCESS,
  payload,
})

// update

export const updateAbout = payload => ({
  type: actionTypes.UPDATE_ABOUT,
  payload,
})

export const updatePrivacy = payload => ({
  type: actionTypes.UPDATE_PRIVACY,
  payload,
})

export const updateTerm = payload => ({
  type: actionTypes.UPDATE_TERM,
  payload,
})

export const resetResourceState = () => ({
  type: actionTypes.RESET_RESOURCE_STATE,
})
