import { actionTypes } from "./action"

const INIT_STATE = {
  loading: false,
  radiatorsSettings: {},
  success: null,
}

const RadiatorReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get radiator
    case actionTypes.GET_RADIATORS_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_RADIATORS_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        radiatorsSettings: action.payload,
      }

    // update radiator
    case actionTypes.UPDATE_RADIATORS_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_RADIATORS_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }

    // reset radiator
    case actionTypes.RESET_RADIATOR_SETTINGS_STATES:
      return {
        ...state,
        loading: false,
        success: null,
      }
    default:
      return state
  }
}

export default RadiatorReducer
