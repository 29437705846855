export const actionTypes = {
  GET_RADIATORS_SETTINGS: "GET_RADIATORS_SETTINGS",
  GET_RADIATORS_SETTINGS_SUCCESS: "GET_RADIATORS_SETTINGS_SUCCESS",
  // update radiator
  UPDATE_RADIATORS_SETTINGS: "UPDATE_RADIATORS_SETTINGS",
  UPDATE_RADIATORS_SETTINGS_SUCCESS: "UPDATE_RADIATORS_SETTINGS_SUCCESS",
  //   reset radiator
  RESET_RADIATOR_SETTINGS_STATES: "RESET_RADIATOR_SETTINGS_STATES",
}

export const getRadiatorsSettings = payload => ({
  type: actionTypes.GET_RADIATORS_SETTINGS,
  payload,
})

export const getRadiatorsSettingsSuccess = payload => ({
  type: actionTypes.GET_RADIATORS_SETTINGS_SUCCESS,
  payload,
})

// update radiator
export const updateRadiatorsSettings = payload => ({
  type: actionTypes.UPDATE_RADIATORS_SETTINGS,
  payload,
})

export const updateRadiatorsSettingsSuccess = payload => ({
  type: actionTypes.UPDATE_RADIATORS_SETTINGS_SUCCESS,
  payload,
})

// reset radiator
export const resetRadiatorsSettingsStates = () => ({
  type: actionTypes.RESET_RADIATOR_SETTINGS_STATES,
})
