import PropTypes from "prop-types"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { Form, Input, Upload, Space, ColorPicker, Spin } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { getCurrentDateWithOptions } from "utils/datesFormators"
import { getClientBranding, updateBranding } from "store/branding/actions"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { validatePhoneNumber, validatePostcode } from "../../utils/validators"
//i18n
import { withTranslation } from "react-i18next"

const EditBranding = props => {
  document.title = "Branding | TRUST"
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { currentUser } = useSelector(state => state.Login)
  const { loading, clientBranding } = useSelector(
    state => state.BrandingReducer
  )

  const logoUrl =
    "https://trust-electric-api.knarkzdev.com/public/profile_images/default_profile_image.png"
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    dispatch(
      getClientBranding({
        client_id: currentUser.organization_id,
        application_type: 1,
      })
    )
  }, [])

  useEffect(() => {
    if (logoUrl) {
      // fetch the url and convert it into file object
      const stringParts = logoUrl.split("/")
      const fileName = stringParts[stringParts.length - 1]

      // axios
      //   .get(logoUrl, {
      //     responseType: "blob",
      //   })
      //   .then(response => {
      //     const fileBlob = response.data
      //     const uid =
      //       "rc-upload-" +
      //       Date.now() +
      //       "-" +
      //       Math.floor(Math.random() * 90) +
      //       10
      //     const file = new File([fileBlob], fileName, {
      //       type: fileBlob.type,
      //       lastModified: Date.now(),
      //     })

      //     file.uid = uid

      //     form.setFieldValue("companyLogo", file)
      //   })
      //   .catch(error => {
      //     console.error("Error fetching file:", error)
      //     return null
      //   })

      form.setFieldValue("companyLogo", logoUrl)
      setFileList([
        {
          uid: "-1",
          name: fileName,
          status: "done",
          url: logoUrl,
        },
      ])
    }
  }, [logoUrl])

  useEffect(() => {
    if (clientBranding) {
      form.setFieldValue("client_name", `${clientBranding.name}`)
      form.setFieldValue("contactnumber", `${clientBranding.mobile}`)
      form.setFieldValue("email", `${clientBranding.email}`)
      form.setFieldValue("AddressLine1", `${clientBranding.address_line1}`)
      form.setFieldValue("AddressLine2", `${clientBranding.address_line2}`)
      form.setFieldValue("city", `${clientBranding.city}`)
      form.setFieldValue("country", `${clientBranding.country}`)
      form.setFieldValue("state", `${clientBranding.state}`)
      form.setFieldValue("postcode", `${clientBranding.post_code}`)
      form.setFieldValue(
        "geofencing_radius",
        `${clientBranding.geofencing_radius}`
      )
      form.setFieldValue("primarycolor", clientBranding.primary_color)
      form.setFieldValue("primarydark", clientBranding.primary_dark_color)
      form.setFieldValue("secondarycolor", clientBranding.secondary_color)
      form.setFieldValue("accentcolor", clientBranding.accent_color)
      form.setFieldValue("backgroundcolor", clientBranding.background_color)
      form.setFieldValue("titleTextColor", clientBranding.title_text_color)
      form.setFieldValue("headerTextColor", clientBranding.header_text_color)
      form.setFieldValue("buttonTextColor", clientBranding.button_text_color)
    }
  }, [clientBranding])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Branding" />
          {Object.keys(clientBranding).length > 0 ? (
            <Row>
              <Col>
                <Form
                  form={form}
                  layout="vertical"
                  size="large"
                  initialValues={{}}
                  onFinish={values => {
                    values.typeId = 1
                    values.client_id = currentUser?.organization_id
                    dispatch(updateBranding(values))
                  }}
                >
                  {/* company information */}
                  <>
                    <Form.Item>
                      <Form.Item
                        name="client_name"
                        label="Client Name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Client Name!",
                          },
                        ]}
                        // style={{
                        //   display: "inline-block",
                        //   width: "calc(50% - 8px)",
                        // }}
                      >
                        <Input placeholder="Enter Client Name" />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item>
                      <Form.Item
                        name="contactnumber"
                        label="Contact Number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Contact Number!",
                          },
                          { validator: validatePhoneNumber },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                        }}
                      >
                        <Input placeholder="Enter Contact Number" />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Email",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                          margin: "0 8px",
                        }}
                      >
                        <Input placeholder="Enter Your Email" />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item>
                      <Form.Item
                        name="AddressLine1"
                        label="Address line 1"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Address line 1!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                        }}
                      >
                        <Input placeholder="Enter Address Line one" />
                      </Form.Item>
                      <Form.Item
                        name="AddressLine2"
                        label="Address line 2"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Address line 2!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                          margin: "0 8px",
                        }}
                      >
                        <Input placeholder="Enter Address line 2" />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item>
                      <Form.Item
                        name="city"
                        label="City"
                        rules={[
                          {
                            required: true,
                            message: "Please input your City!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                        }}
                      >
                        <Input placeholder="Enter City" />
                      </Form.Item>
                      <Form.Item
                        name="country"
                        label="Country"
                        rules={[
                          {
                            required: true,
                            message: "Please input Country",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                          margin: "0 8px",
                        }}
                      >
                        <Input placeholder="Enter Country" />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item>
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: "Please input your State!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                        }}
                      >
                        <Input placeholder="Enter State" />
                      </Form.Item>
                      <Form.Item
                        name="postcode"
                        label="Post Code"
                        rules={[
                          {
                            required: true,
                            message: "Please input Post Code",
                          },
                          { validator: validatePostcode },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                          margin: "0 8px",
                        }}
                      >
                        <Input placeholder="Enter Post Code" />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item>
                      <Form.Item
                        name="geofencing_radius"
                        label="Geofencing Radius"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Geofencing Radius!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Enter Geofencing Radius"
                          min={0}
                        />
                      </Form.Item>
                    </Form.Item>
                  </>
                  {/* Branding colors */}
                  <>
                    <Form.Item>
                      <Form.Item
                        name="primarycolor"
                        label="Primary Color"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Primary Color!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                        }}
                      >
                        <Input
                          className="form-control form-control-color w-100"
                          type="color"
                          id="example-color-input8"
                        />
                      </Form.Item>
                      <Form.Item
                        name="primarydark"
                        label="Primary Dark"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Primary Dark!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                          margin: "0 8px",
                        }}
                      >
                        <Input
                          className="form-control form-control-color w-100"
                          type="color"
                          id="example-color-input7"
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item>
                      <Form.Item
                        name="secondarycolor"
                        label="Secondary Color"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Secondary Color!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                        }}
                      >
                        <Input
                          className="form-control form-control-color w-100"
                          type="color"
                          id="example-color-input6"
                        />
                      </Form.Item>
                      <Form.Item
                        name="accentcolor"
                        label="Accent Color"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Accent Color",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                          margin: "0 8px",
                        }}
                      >
                        <Input
                          className="form-control form-control-color w-100"
                          type="color"
                          id="example-color-input5"
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item>
                      <Form.Item
                        name="backgroundcolor"
                        label="Background Color"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Background Color!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                        }}
                      >
                        <Input
                          className="form-control form-control-color w-100"
                          type="color"
                          id="example-color-input4"
                        />
                      </Form.Item>
                      <Form.Item
                        name="titleTextColor"
                        label="Title Text Color"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Title Text Color!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                          margin: "0 8px",
                        }}
                      >
                        <Input
                          className="form-control form-control-color w-100"
                          type="color"
                          id="example-color-input1"
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item>
                      <Form.Item
                        name="headerTextColor"
                        label="Header Text Color"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Header Text Color!",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                        }}
                      >
                        <Input
                          className="form-control form-control-color w-100"
                          type="color"
                          id="example-color-input2"
                        />
                      </Form.Item>
                      <Form.Item
                        name="buttonTextColor"
                        label="Button Text Color"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Button Text Color",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 8px)",
                          margin: "0 8px",
                        }}
                      >
                        <Input
                          className="form-control form-control-color w-100"
                          type="color"
                          id="example-color-input3"
                        />
                      </Form.Item>
                    </Form.Item>
                    {/* Profile Logo */}
                    {/* <Form.Item>
                    <Form.Item
                      name="companyLogo"
                      label="Logo"
                      rules={[
                        {
                          required: true,
                          message: "Please Provide Logo!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Upload
                        type="image/*"
                        maxCount={1}
                        onPreview={e => e.preventDefault}
                        listType="picture-card"
                        fileList={fileList}
                        onChange={({ file, fileList }) => {
                          // console.log(
                          //   "fileList[0]?.originFileObj",
                          //   fileList[0]?.originFileObj
                          // )
                          setFileList(fileList)
                          file.status = "done"
                          form.setFieldValue(
                            "companyLogo",
                            fileList[0]?.originFileObj
                          )
                        }}
                        onRemove={() => {
                          setFileList([])
                          setTimeout(() => {
                            form.setFieldValue("companyLogo", "")
                          }, 0)
                        }}
                        customRequest={({ file, onSuccess, onError }) => {
                          onSuccess()
                        }}
                      >
                        {fileList.length === 0 && "+ Upload"}
                      </Upload>
                    </Form.Item>
                  </Form.Item> */}
                  </>
                  <Form.Item>
                    <Space
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        className="bg-primary w-xl text-white px-4"
                        type="primary"
                        htmltype="submit"
                        disabled={loading}
                      >
                        Save Changes
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          ) : (
            <Row className="">
              <Col className="d-flex justify-content-center">
                <Spin />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

EditBranding.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(EditBranding)
