import { actionTypes } from "./actions"

const INIT_STATE = {
  loading: false,
  notificationData: [],
}

const Notification = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationData: action.payload,
      }

    default:
      return state
  }
}

export default Notification
