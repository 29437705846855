import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Dashboard from "./dashboard/reducer"
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import ProfileReducer from "./auth/profile/reducer"
import RadiatorReducer from "./radiators/reducer"
import ResourcesReducer from "./resources/reducer"
import BrandingReducer from "./branding/reducer"
import NotificationReducer from "./notification/reducer"
//Calendar
import calendar from "./calendar/reducer"

const rootReducer = combineReducers({
  Dashboard,
  Layout,
  Login,
  Account,
  ForgetPassword,
  ResourcesReducer,
  RadiatorReducer,
  ProfileReducer,
  BrandingReducer,
  NotificationReducer,
  calendar,
})

export default rootReducer
