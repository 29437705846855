import PropTypes from "prop-types"
import React, { useState } from "react"
import { Form, Input, Upload, Space, ColorPicker } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
// Custom Scrollbar
import SimpleBar from "simplebar-react"

import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"

const ContactUs = props => {
  const [form] = Form.useForm()
  document.title = "Contact Us | TRUST"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb breadcrumbItem="Contact Us" />
          <Row>
            <Col>
              <Form
                form={form}
                layout="vertical"
                size="large"
                // initialValues={{
                //   requiredMarkValue: requiredMark,
                // }}
                // onValuesChange={onRequiredTypeChange}
                // requiredMark={
                //   requiredMark === "customize"
                //     ? customizeRequiredMark
                //     : requiredMark
                // }
              >
                <Form.Item>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Name!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Description!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={100}
                      //   onChange={onChange}
                      placeholder="Description"
                      style={{
                        height: 120,
                        resize: "none",
                      }}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item></Form.Item>
                <Form.Item>
                  <Space style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      className="bg-primary w-xl text-white px-4"
                      type="primary"
                      htmltype="submit"
                    >
                      Save Changes
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ContactUs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ContactUs)
