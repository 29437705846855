import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Switch, Table, Statistic } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { getRadiatorsSettings } from "store/radiators/action"
import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"

const RadiatorSettings = props => {
  document.title = "Radiators Settings | TRUST"
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { radiatorsSettings, loading } = useSelector(
    state => state.RadiatorReducer
  )

  const { currentUser } = useSelector(state => state.Login)

  useEffect(() => {
    dispatch(getRadiatorsSettings({ org_id: currentUser.organization_id }))
  }, [])

  const radiatorSetting = [radiatorsSettings]

  const columns = [
    {
      title: "Minimum Temperature",
      dataIndex: "min_temp",
      align: "center",
      render: (data, record) => (
        <>
          <Statistic
            valueStyle={{
              fontSize: "20px",
            }}
            value={data}
          />
        </>
      ),
    },
    {
      title: "Maximum Temperature",
      dataIndex: "max_temp",
      align: "center",
      render: (data, record) => (
        <>
          <Statistic
            valueStyle={{
              fontSize: "20px",
            }}
            value={data}
          />
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (data, record) => (
        <>
          {" "}
          <Button
            onClick={() =>
              navigate("/edit-radiator-settings", { state: { record } })
            }
            className="bg-primary text-white"
          >
            Edit
          </Button>
        </>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Radiators Settings" />
          <Row>
            <Table
              scroll={{
                x: 1300,
              }}
              columns={columns}
              dataSource={radiatorSetting}
              rowClassName="center-row"
              pagination={{
                position: ["none", "none"],
              }}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

RadiatorSettings.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(RadiatorSettings)
