export const actionTypes = {
  GET_CLIENT_BRANDING: "GET_CLIENT_BRANDING",
  GET_CLIENT_BRANDING_SUCCESS: "GET_CLIENT_BRANDING_SUCCESS",
  UPDATE_BRANDING: "UPDATE_BRANDING",
  //  reset states
  RESET_BRANDING_STATE: "RESET_BRANDING_STATE",
}

// get current client information
export const getClientBranding = payload => ({
  type: actionTypes.GET_CLIENT_BRANDING,
  payload,
})

export const getClientBrandingSuccess = payload => ({
    type: actionTypes.GET_CLIENT_BRANDING_SUCCESS,
    payload,
  })

export const updateBranding = payload => ({
  type: actionTypes.UPDATE_BRANDING,
  payload,
})

export const resetBrandingStates = () => ({
  type: actionTypes.RESET_BRANDING_STATE,
})
